export default [
  'Abbyson Living',
  'Absorba',
  'Ace',
  'Addie & Tate', 
  'Aden + Anais',
  'Adidas', 
  'Adora',
  'Aimee Kestenberg',
  'Air Innovations',
  'ALEX Toys',
  'Ameda',
  'Amloid',
  'Anchor Animals',
  'Anchor Hocking',
  'Angel Care',
  'Animal Adventure',
  'Anna & Eve',
  'A Pea in the Pod',
  'Aqua Leisure',
  'Aquaphor',
  'Arlo',
  'Arm\'s Reach',
  'Attitude',
  'Asher and Olivia',
  'Atwater Living', 
  'Aurora World',
  'Avanchy',
  'Aveeno',
  'Babiators',
  'Babo botanicals',
  'Baby Appleseed',
  'Baby Aspen',
  'Baby Banana',
  'Baby Banz',
  'Baby Biscotti', 
  'Baby Bling',
  'Baby Buddy',
  'Baby Bum',
  'Baby bumco',
  'Baby Buns',
  'Baby Cache',
  'BABY CARE',
  'Baby Deedee',
  'Baby Delight',
  'Baby Diego',
  'Baby Ecos',
  'Baby Einstein',
  'Baby Essentials',
  'Baby Jogger',
  'Baby K\'Tan',
  'Baby Lounge',
  'Baby Magic',
  'Baby Patent',
  'Baby Relax',
  'Baby Shark',
  'Baby Shusher',
  'Baby Starters',
  'Baby Trend',
  'Baby Vision',
  'Baby works',
  'Baby Bjorn',
  'Baby Breeze',
  'BabyDan',
  'Babyganics',
  'babyGear',
  'Babyletto', 
  'BabyMel',
  'Babymoov',
  'Babyzen',
  'Balboa Baby',
  'Bambino',
  'Bambo Nature',
  'Bamboobies',
  'Banana Fish',
  'Banzai',
  'Barbie', 
  'Bare',
  'Barron\'s Educational Series',
  'Basq',
  'Bazzle Baby',
  'bb Basic',
  'bbluv',
  'beaba',
  'Beanko',
  'Bebe Au Lait',
  'Bebitza',
  'Beco',
  'BEDGEAR',
  'Bee & Coco',
  'Beech-Nut',
  'Beetle & Thread',
  'Bella B',
  'Bella Tunno',
  'Bellema',
  'Belli',
  'Belly Bandit',
  'Bellybuds',
  'Bentley',
  'Best Ride on Cars',
  'Bestaroo',
  'Bfree',
  'Big Red Rooster',
  'Biltmore',
  'Bink',
  'Blazin Wheels',
  'Bliss',
  'Blooming Baby',
  'Blue Lizard',
  'BlueAvocado',
  'Bluekiwi',
  'BOB Gear',
  'Boba',
  'Body after baby',
  'Boogie Wipes', 
  'BogginHead',
  'Boon',
  'Boppy',
  'Brica',
  'Bugaboo',
  'Buggygear',
  'Bumbo',
  'Bumkins',
  'Burt\'s Bee',
  'Burt\'s Bee Baby',
  'CarePlay',
  'Carter\'s',
  'CeraVe',
  'Cetaphil',
  'CheekyChompers ',
  'ChangePal',
  'Chewbeads',
  'Classic World Toys',
  'comotomo',
  'coppertone',
  'Cosi Bella',
  'Cybex',
  'DaVinci',
  'Destination Maternity',
  'DockaTot',
  'Dono&Dono',
  'Doona',
  'Earth Mama',
  'Eezy Peezy Monkey Bar Set',
  'Ergobaby',
  'Evenflo',
  'ezpz',
  'Exotic Goddess Serum',
  'Fat Brain Toys',
  'Finn by Finn & Emma',
  'Fisher Price',
  'Frida Mom',
  'Frida Baby',
  'Fruit of the Loom',
  'Gerber',
  'Gi-Go Toys',
  'Goumi',
  'Graco',
  'green sprouts',
  'Gunapod Wonderzip',
  'Haakaa',
  'Halo',
  'Happiest Baby',
  'Happy Baby',
  'Hasbro',
  'Hatch',
  'Huggies',
  'Hurley',
  'iBaby',
  'Infant Optics',
  'Infantino',
  'Inglesina',
  'Intex',
  'Johnson & Johnson',
  'Joovy',
  'Just Born',
  'Keekaroo',
  'KidzTech',
  'Kiinde',
  'Kikklerland',
  'Kushies',
  'Lamaze',
  'Lansinoh',
  'Leap Frog',
  'Living Textiles',
  'Love to Dream',
  'Lullaby Earth',
  'LulyBoo',
  'Luvable Friends',
  'Maclaren',
  'Mama Bees Relaxation Collection',
  'MAM',
  'Manhattan Toys',
  'Mattel',
  'Maxi-Cosi',
  'Medela',
  'Mega Bloks',
  'Melissa and Doug',
  'Miku',
  'milkmakers',
  'Mima',
  'Mindware',
  'Mommy & Me',
  'Motherhood Maternity',
  'Munchkin',
  'Mustachifier', 
  'Mustela',
  'My Baby Sam',
  'My Brest Friend',
  'Newton Baby', 
  'Nomi',
  'Noodle & Boo',
  'Nook Sleep system',
  'Nuby',
  'NUK',
  'NumNum',
  'Organic Farm Buddies',
  'OshKosh B\'gosh',
  'Ozlo Baby',
  'Oxford baby',
  'pacific baby',
  'pacific play tent',
  'pampers',
  'Pedialyte',
  'Pehr',
  'Petit Lem',
  'Petunia pickle bottom',
  'Philips Avent',
  'Pink Blush Maternity',
  'Pink fong',
  'Piyo Piyo',
  'Playtex',
  'PopYum',
  'Posh',
  'Potty Safe',
  'PurePail',
  'Real Kids Shades',
  'Rosie Pope',
  'Safety 1st',
  'Sassy',
  'Seraphine',
  'seventh Generation',
  'Silli Chews',
  'Similac',
  'skip hop',
  'Snackeez',
  'Soho Baby',
  'Sophie la Girafe',
  'sorelle',
  'Spectra',
  'Squishable',
  'step2',
  'stokke',
  'Tadpoles',
  'Teeny Tiny Treatments',
  'teetherpops',
  'Tempur-Pedic',
  'The 1st Chair',
  'The Cibo',
  'The Honest Company',
  'Ubbi',
  'UPPAbaby',
  'Uveez',
  'Vtech',
  'Warmies',
  'WOW baby',
  'Wow cup',
  'WubbaNub',
  '3 sprout',
  '7 A.M. Enfant',
  ];