/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:daa1a7b5-e3d8-46e1-abb4-7e905d78a3d1",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_k1Rx1v1YB",
    "aws_user_pools_web_client_id": "tkajoka9nvfaia1h5pdm837o9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7p7wixxgizcz5oghgck4kbthkq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-62wqdi5mdbcohbpphfkfmpr76u",
    "aws_cloud_logic_custom": [
        {
            "name": "imgapi",
            "endpoint": "https://lpdkwh8n9d.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "paircareeedc81d42a624e82bb093d1e21f1fbfbprod-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
