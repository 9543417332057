export default [
  {
    value: 0,
    label: '0',
  },
  {
    value: 6,
    label: '6W',
  },
  {
    value: 12,
    label: '12W',
  },
  {
    value: 18,
    label: '18W'
  },
  {
    value: 24,
    label: '24W'
  },
  {
    value: 30,
    label: '30W'
  },
  {
    value: 36,
    label: '36W'
  },
  {
    value: 42,
    label: '42W'
  }
];