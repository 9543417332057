export default [
  {
    value: 0,
    label: 'NB',
  },
  {
    value: 6,
    label: '6M',
  },
  {
    value: 12,
    label: '1Y',
  },
  {
    value: 18,
    label: '18M'
  },
  {
    value: 24,
    label: '2Y',
  },
  {
    value: 36,
    label: '3Y',
  },
  {
    value: 48,
    label: '4Y',
  },
  {
    value: 60,
    label: '5Y',
  }
];